/* src/components/TaskModal.css */

@keyframes rotateGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.update-button {
    position: relative;
    overflow: visible;
    transition: all 0.3s ease;
    padding: 6px;
}

.update-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg,
    rgba(249, 119, 148, 0.9),
    rgba(98, 0, 255, 0.9),
    rgba(52, 152, 219, 0.9),
    rgba(249, 119, 148, 0.9)
    );
    background-size: 300% 300%;
    z-index: 1;
    border-radius: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.update-button.animate::before {
    opacity: 1;
    animation: rotateGradient 5s ease-in-out infinite;
}

.update-button-content {
    background: #3498db;
    display: block;
    position: relative;
    z-index: 2;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    color: white;
    transition: background-color 0.3s ease;
}

.update-button:hover .update-button-content {
    background-color: #2980b9; /* Darker shade for hover effect */
}

.update-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.update-button:disabled:hover .update-button-content {
    background-color: #3498db; /* No hover effect when disabled */
}